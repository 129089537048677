import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DirectivesModule } from './core/shared/directives/directives.module';
import { ApiHttpService } from './core/services/api-http.service';
import { ApiEndpointsService } from './core/services/api-endpoints.service';
import { UIService } from './core/services/ui.service';
import { DatePipe } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AuthInterceptor } from './core/services/auth-interceptor';
import { AppMainComponent } from './app-main/app-main.component';
import { LoginComponent } from './login/login.component';
import { WelcomePageComponent } from './app-main/welcome-page/welcome-page.component';
import { ConfirmDialogComponent } from './core/shared/components/confirm-dialog.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ArrayFieldSumPipe, DateLessThanCrtTimePipe, TimeToDatePipe } from './core/shared/pipes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { MatSelectFilterComponent } from './mat-select-filter.component';
import { GoogleChartsModule } from 'angular-google-charts';

@NgModule({
  declarations: [
    AppComponent,
    AppMainComponent,
    LoginComponent,
    WelcomePageComponent,
    ConfirmDialogComponent,
    DashboardComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // NoopAnimationsModule,
    HttpClientModule,
    FormsModule,
    MaterialModule,
    GoogleMapsModule,
    DirectivesModule,
    BrowserAnimationsModule,
    GoogleChartsModule
  ],
  providers: [
    ApiHttpService,
    ApiEndpointsService,
    UIService,
    DatePipe,
    TimeToDatePipe,
    {provide: MAT_DATE_LOCALE, useValue: 'en-IN'},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi:true},    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
