// Angular Modules
import { Injectable } from '@angular/core';

import { ApiHttpService } from './api-http.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UIService } from './ui.service';
import { Router } from '@angular/router';
import { ApiRoutes } from '../shared/apiroutes';
import { AuthApiResponse, NodalPoint, NodalRoute, Organization, ShiftGroup, StopCluster, Stops, SupportTicket, Trips, User, Vehicle, } from '../shared/interfaces';

import { environment } from 'src/environments/environment';
import { UrlHelper } from '../shared/url-helper';
import Logging from './Logging';
import { OrgType, UserType } from '../shared/types';
import { NgForm } from '@angular/forms';
import { ManualRosteringStop } from '../shared/ManualRosteringStop';

@Injectable()
export class ApiEndpointsService {
  public userChanged = new BehaviorSubject(false);
  private token: string | undefined = undefined;
  private isAuth = false;
  private tokenTimer: any | undefined;
  private SEC_TO_MILLISEC : number = 1000;

  getToken() {
    return this.token;
  }
  getIsAuth() {
    return this.isAuth;
  }

  private authStatusListener = new Subject<Boolean>();
  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  baseUrl: string = environment.api_endpoint;

  userProfile: User = {
    firstname: '',
    lastname: '',
    email: '',
    mobile: '',
    password: '',
    gender: '',
    org_id: 0
  };

  userOrganization: Organization | undefined = undefined;
  userParentOrganization: Organization | undefined = undefined;

  constructor(
    private apiHttpService: ApiHttpService,
    private uiService: UIService,
    private _router: Router
  ) {}
 
  // LOGi(message?: any, ...optionalParams: any[]){
  //   if(environment.loggingI) console.log(message, ...optionalParams)
  // }
  // LOGe(message?: any, ...optionalParams: any[]){
  //   if(environment.loggingE) this.LOGe(message, ...optionalParams)
  // }

  getIsUserType(type : UserType): boolean{
    return this.userProfile.type == type;
  }
  getIsOrgType(type : OrgType): boolean{
    return this.userOrganization?.org_type == type;
  }
  orgHasFeature(feature : string): boolean{
    if (this.userOrganization?.webfeatures) {
      return this.userOrganization?.webfeatures?.includes(feature);
      
    } else {
      return false;
    }
  }


  private employeeStops = (): string => UrlHelper.createUrl(ApiRoutes.EmployeeStops);
  
  private employeeStopsById = (id: number): string =>
    UrlHelper.createUrlWithPathVariables(ApiRoutes.EmployeeStops, [id]);
  deleteRosterStopByEmployee = (stopId: number): Observable<any> =>
    this.apiHttpService.delete(this.employeeStopsById(stopId));
  
  markNoShowRosterStopByAdmin = (stopId: number): Observable<any> =>
    this.apiHttpService.patch(UrlHelper.createUrlWithPathVariables(ApiRoutes.MarkNoShowStop, [stopId]),null);

  createRosterStops = (formValue: any): Observable<any> =>
    this.apiHttpService.post(UrlHelper.createUrl(ApiRoutes.STOPS), formValue);
  deleteRosterStopsAdmin = (id: number): Observable<any> =>
    this.apiHttpService.delete(UrlHelper.createUrlWithPathVariables(ApiRoutes.STOPS,[id]));

  public all_employee_trips_changed = new BehaviorSubject(false);
  all_employee_trips : any[] = []
  // indexedUsers: { [key:string]: User } = {}
  getEmployeeTripsAgenda() {
    this.uiService.setLoading(true);
    this.apiHttpService.get(this.employeeStops())
      .subscribe({
        next: (value) => {
          Logging.info('getEmployeeTripsAgenda', value)
          // this.LOGi('getUsers', value);
          this.all_employee_trips = value.data;
          this.all_employee_trips_changed.next(true);
          this.uiService.showSnackBar(value.message, 'okay', 5000);
        },
        error: (error) => {
          Logging.error('getEmployeeTripsAgenda', error)
          this.uiService.showSnackBar(error.message, 'okay', 5000);
        },
        complete: () => {},
      })
      .add(() => this.uiService.setLoading(false));
  }

  public device_events = (): string =>
  UrlHelper.createUrl('events/history');

  public roster_view_trips_populated = (): string => UrlHelper.createUrl(`${ApiRoutes.RosterView}`);
  public roster_view_trips_populated_filtered = (): string => UrlHelper.createUrl(`${ApiRoutes.FilteredRosterView}`);
  public roster_view_trips_shifts_populated = (): string => UrlHelper.createUrl(`${ApiRoutes.RosterShiftsView}`);

  public dashboard = (): string => UrlHelper.createUrl(`${ApiRoutes.Dashboard}`);
  public roster_trips_populated = (): string => UrlHelper.createUrl(`${ApiRoutes.Roster}`);
  public bookings_trips_populated = (): string => UrlHelper.createUrl(`${ApiRoutes.BookingsTrips}`);
  public roster_trips_populated_filtered = (): string => UrlHelper.createUrl(`${ApiRoutes.FilteredRoster}`);
  public roster_trips_shifts_populated = (): string => UrlHelper.createUrl(`${ApiRoutes.RosterShifts}`);
  public company_view_trips = (): string => UrlHelper.createUrl(`${ApiRoutes.CompanyTripsView}`);

  saveTrip(tripForm: any, selectedStops: (Stops | ManualRosteringStop | undefined)[]): Observable<any> {
    Logging.info('saveTrip', tripForm.value);

    let trip = tripForm.value; // as IManualRosteringTrip;
    if (trip.driverId == '') {
      delete trip.driverId;
    }
    if (trip.vehicleId == '') {
      delete trip.vehicleId;
    }
    if (tripForm.value['stop_ids']?.length > 0) {
      const fisrtStopId = tripForm.value['stop_ids'][0];
      const firstStop = selectedStops.filter(x=> x?.id == fisrtStopId) as ManualRosteringStop[];
      if (firstStop.length == 1) {
        tripForm.value['trip_type'] = firstStop[0].roster_type;
        tripForm.value['in_time'] = firstStop[0].in_time;
        tripForm.value['shift_group_id'] = firstStop[0].shift_group_id;
        tripForm.value['company_shift_id'] = firstStop[0].company_shift_id;
        tripForm.value['is_starting_from_office'] = firstStop[0].is_starting_from_office;
      }
    }
    // this.uiService.setLoading(true);
    return !!trip.id
      ? this.editTrip(trip)
      : this.createTrip(trip);
  }

  // NodalPoints API Start
  private nodalPoint = (): string => UrlHelper.createUrl(ApiRoutes.NodalPoints);
  private nodalPointById = (id: string): string =>
    UrlHelper.createUrlWithPathVariables(ApiRoutes.NodalPoints, [id]);
    
  private nodalPointsByCompanyId = (id: string): Observable<any> => {
    return this.apiHttpService.get(UrlHelper.createUrlWithPathVariables(ApiRoutes.NodalPointsByCompanyId, [id]))
  };
  getCompanyShiftsByCompanyId = (id: string): Observable<any> => {
    return this.apiHttpService.get(UrlHelper.createUrlWithPathVariables(ApiRoutes.CompanyShiftByCompany, [id]))
  };

  // NodalRoutes API Start
  private nodalRoute = (): string => UrlHelper.createUrl(ApiRoutes.NodalRoute);
  private nodalRouteById = (id: string): string =>
    UrlHelper.createUrlWithPathVariables(ApiRoutes.NodalRoute, [id]);
    
  private nodalRoutesByCompanyId = (id: string): Observable<any> => {
    return this.apiHttpService.get(UrlHelper.createUrlWithPathVariables(ApiRoutes.NodalRoutesByCompanyId, [id]))
  };

  // Users API Start
  private user = (): string => UrlHelper.createUrl(ApiRoutes.USER);
  private userById = (id: string): string =>
    UrlHelper.createUrlWithPathVariables(ApiRoutes.USER, [id]);
    
  private getAllUser = (): Observable<any> => this.apiHttpService.get(this.user());
  private usersByCompanyId = (id: string): Observable<any> => {
    const path = UrlHelper.createUrlWithPathVariables(ApiRoutes.UserByCompany, [id]);
    Logging.log(path)
    return this.apiHttpService.get(UrlHelper.createUrlWithPathVariables(ApiRoutes.UserByCompany, [id]))
  };
  
  sendOtp = (formValue: any): Observable<any> =>
    this.apiHttpService.post(UrlHelper.createUrl(ApiRoutes.SendOTP), formValue);

  createUser = (formValue: any): Observable<any> =>
    this.apiHttpService.post(this.user(), formValue);

  editUser = (formValue: any): Observable<any> =>
    this.apiHttpService.patch(this.userById(formValue.id), formValue);
  getUser = (userId: string): Observable<any> =>
    this.apiHttpService.get(this.userById(userId));

  // public change_user_pass = (_id: string): string => UrlHelper.createUrlWithPathVariables('users/changePass', [_id]);
  deleteUser = (userId: string): Observable<any> =>
    this.apiHttpService.delete(this.userById(userId));

  public all_accounts_changed = new BehaviorSubject(false);
  all_accounts : User[] = []
  indexedUsers: { [key:string]: User } = {}
  getUsers(comapnyId:any) {
    
    Logging.error('comapnyId',comapnyId);
    // if(!!comapnyId) {
    //   Logging.error('observer comapnyId',comapnyId);
    //   observer = this.usersByCompanyId(comapnyId)
    // }{
    //   observer = this.getAllUser()
    // }
    const observer = comapnyId ? this.usersByCompanyId(comapnyId):this.getAllUser()
    this.uiService.setLoading(true);
    observer
    .subscribe({
        next: (value) => {
          Logging.info('getUsers', value)
          this.all_accounts = value.data;
          this.all_accounts_changed.next(true);
          this.uiService.showSnackBar(value.message, 'okay', 5000);
        },
        error: (error) => {
          Logging.error('getUsers', error)
          this.uiService.showSnackBar(error.message, 'okay', 5000);
        },
        complete: () => {},
      })
      .add(() => this.uiService.setLoading(false));
  }
  
  public all_nodals_points_changed = new BehaviorSubject(false);
  all_nodals_points : NodalPoint[] = []
  getNodalPoints(comapnyId:any) {
    
    Logging.error('comapnyId',comapnyId);
    const observer = comapnyId ? this.nodalPointsByCompanyId(comapnyId):this.apiHttpService.get(this.nodalPoint())
    this.uiService.setLoading(true);
    observer
    .subscribe({
        next: (value) => {
          Logging.info('getNodalPoints', value)
          this.all_nodals_points = value.data;
          this.all_nodals_points_changed.next(true);
          this.uiService.showSnackBar(value.message, 'okay', 5000);
        },
        error: (error) => {
          Logging.error('getNodalPoints', error)
          this.uiService.showSnackBar(error.message, 'okay', 5000);
        },
        complete: () => {},
      })
      .add(() => this.uiService.setLoading(false));
  }

  public all_nodals_routes_changed = new BehaviorSubject(false);
  all_nodals_routes : NodalRoute[] = []
  getNodalRoutes(comapnyId:any) {
    
    Logging.error('comapnyId',comapnyId);
    const observer = comapnyId ? this.nodalRoutesByCompanyId(comapnyId):this.apiHttpService.get(this.nodalRoute())
    this.uiService.setLoading(true);
    observer
    .subscribe({
        next: (value) => {
          Logging.info('getNodalRoutes', value)
          this.all_nodals_routes = value.data;
          this.all_nodals_routes_changed.next(true);
          this.uiService.showSnackBar(value.message, 'okay', 5000);
        },
        error: (error) => {
          Logging.error('getNodalRoutes', error)
          this.uiService.showSnackBar(error.message, 'okay', 5000);
        },
        complete: () => {},
      })
      .add(() => this.uiService.setLoading(false));
  }

  private org = (): string => UrlHelper.createUrl(ApiRoutes.ORGANIZATION);
  private orgById = (id: string): string =>
  UrlHelper.createUrlWithPathVariables(ApiRoutes.ORGANIZATION, [id]);
  
  
  private shiftGroupsByCompanyId = (id: string): Observable<any> => this.apiHttpService.get(UrlHelper.createUrlWithPathVariables(ApiRoutes.SHIFTGROUPS, [id]));
  
  private getAllOrg = (): Observable<any> => this.apiHttpService.get(this.org());
  createOrg = (formValue: any): Observable<any> =>
    this.apiHttpService.post(this.org(), formValue);
  editOrg = (formValue: any): Observable<any> =>
    this.apiHttpService.patch(this.orgById(formValue.id), formValue);
  getOrg = (orgId: string): Observable<any> =>
    this.apiHttpService.get(this.orgById(orgId));
  deleteOrg = (orgId: string): Observable<any> =>
    this.apiHttpService.delete(this.orgById(orgId));

  public all_organizations_changed = new BehaviorSubject(false);
  all_organizations : Organization[] = []
  indexedOrgs: { [key:number]: string } = {}
  getOrgs() {
    Logging.info('getOrgs','getOrgs')
    this.uiService.setLoading(true);
    this.getAllOrg().subscribe({
      next: (value) => {
        Logging.info('response',value)
        if (value.error) {
          this.uiService.showSnackBar(value.message, 'okay', 5000);
        }else{
          this.all_organizations = value.data;
          this.indexedOrgs = Object.fromEntries(this.all_organizations.map(obj => [obj.id, obj.brand_name]));
          this.all_organizations_changed.next(true);
        }
      },
      error: (error) => {
        
          Logging.error('getUsers', error)
        this.uiService.showSnackBar(error.message, 'okay', 5000);
      },
      complete: () => {},
    }).add(()=>this.uiService.setLoading(false));
  }
  public all_shift_groups_changed = new BehaviorSubject(false);
  all_shift_groups : ShiftGroup[] = []
  // indexedOrgs: { [key:number]: string } = {}
  getShiftGroupsByCompanyId(companyId: any) {
    Logging.info('getShiftGroups','getShiftGroups')
    this.uiService.setLoading(true);
    this.shiftGroupsByCompanyId(companyId).subscribe({
      next: (value) => {
        Logging.info('response',value)
        if (value.error) {
          this.uiService.showSnackBar(value.message, 'okay', 5000);
        }else{
          this.all_shift_groups = value.data;
          this.all_shift_groups_changed.next(true);
        }
      },
      error: (error) => {
        
          Logging.error('getShiftGroups', error)
        this.uiService.showSnackBar(error.message, 'okay', 5000);
      },
      complete: () => {},
    }).add(()=>this.uiService.setLoading(false));
  }
  
  private vehicle = (): string => UrlHelper.createUrl(ApiRoutes.VEHICLE);
  private vehicleById = (id: string): string =>
    UrlHelper.createUrlWithPathVariables(ApiRoutes.VEHICLE, [id]);
  private vehicleByCompanyId = (company_id: any): string =>
    UrlHelper.createUrlWithPathVariables(`${ApiRoutes.VEHICLE}/company`, [company_id]);

  private getAllVehicle = (): Observable<any> => this.apiHttpService.get(this.vehicle());
  createVehicle = (formValue: any): Observable<any> =>
    this.apiHttpService.post(this.vehicle(), formValue);
  editVehicle = (formValue: any): Observable<any> =>
    this.apiHttpService.patch(this.vehicleById(formValue.id), formValue);

  blockVehicle = (vehicleId:any, formValue: any): Observable<any> =>
    this.apiHttpService.patch(UrlHelper.createUrlWithPathVariables(`${ApiRoutes.VEHICLE}/block`, [vehicleId]), formValue);
  unblockVehicle = (vehicleId:any, formValue: any): Observable<any> =>
    this.apiHttpService.patch(UrlHelper.createUrlWithPathVariables(`${ApiRoutes.VEHICLE}/unblock`, [vehicleId]), formValue);
  
  getVehicle = (vehicleId: string): Observable<any> =>
    this.apiHttpService.get(this.vehicleById(vehicleId));
  getVehicleByCompanyId = (companyId: any): Observable<any> =>
    this.apiHttpService.get(this.vehicleByCompanyId(companyId));
  deleteVehicle = (vehicleId: string): Observable<any> =>
    this.apiHttpService.delete(this.vehicleById(vehicleId));

  public all_vehicles_changed = new BehaviorSubject(false);
  all_vehicles : Vehicle[] = []
  getVehicles(company_id=this.userProfile.org_id) {
    this.uiService.setLoading(true);
    
      let observer :Observable<any>;
      switch (this.userProfile.type) {
        case UserType.VendorAdmin:
          observer = this.getAllVehicle()
          break;
        case UserType.CompanyAdmin:
          observer = this.getVehicleByCompanyId(company_id)
          break;
      
        default:
          observer = this.getAllVehicle()
          break;
      }
      // ((this.userProfile.type == UserType.CompanyAdmin || this.userProfile.type == UserType.VendorAdmin) && company_id != 0)
      // ?
      // this.getVehicleByCompanyId(company_id)
      // :
      // this.getAllVehicle()
      observer.subscribe({
      next: (value) => {
        Logging.info('getVehicle', value)
    
        if (value.error) {
          this.uiService.showSnackBar(value.message, 'okay', 5000);
        }else{
          this.all_vehicles = value.data;
          this.all_vehicles_changed.next(true);
        }
      },
      error: (error) => {
        Logging.error('getVehicle', error)
        this.uiService.showSnackBar(error.message, 'okay', 5000);
      },
      complete: () => {},
    }).add(()=>this.uiService.setLoading(false));
  }

  public all_shiftwise_vehicles_changed = new BehaviorSubject(false);
  all_shiftwise_vehicles : Vehicle[] = []
  getVehiclesByShiftIdAndCompanyId(formData:any,) {
    this.uiService.setLoading(true);
      this.apiHttpService.post(UrlHelper.createUrl(`${ApiRoutes.VEHICLE}/getVehiclesByInTime`), formData).subscribe({
      next: (value) => {
        Logging.info('getVehiclesByShiftIdAndCompanyId', value)
    
        if (value.error) {
          this.uiService.showSnackBar(value.message, 'okay', 5000);
        }else{
          this.all_shiftwise_vehicles = value.data;
          this.all_shiftwise_vehicles_changed.next(true);
        }
      },
      error: (error) => {
        Logging.error('getVehiclesByShiftIdAndCompanyId', error)
        this.uiService.showSnackBar(error.message, 'okay', 5000);
      },
      complete: () => {},
    }).add(()=>this.uiService.setLoading(false));
  }
  
  
  public userProfileChanged = new BehaviorSubject(false);

  verifyOtp = (formValue: any) => {
    Logging.info('verifyOtp',formValue);
    this.apiHttpService
      .post(UrlHelper.createUrl(ApiRoutes.verifyOTP), formValue)
      .subscribe({
        next: (value) => {
          Logging.info('response',value)
          const authResponse = value as AuthApiResponse;
          this.setAuthUser(authResponse);
        },
        error: (error) => {
          Logging.info('response error',error)
          
          this.uiService.showSnackBar(error.message, 'okay', 5000);
        },
        complete: () => {},
      })
      .add(() => this.uiService.setLoading(false));
  };

  refreshUser = (formValue: any) => {
    Logging.info('refreshUser',formValue);
    this.apiHttpService
      .post(UrlHelper.createUrl(ApiRoutes.refreshUser),formValue)
      .subscribe({
        next: (value) => {
          Logging.info('response',value)
          const authResponse = value as AuthApiResponse;
          this.setAuthUser(authResponse);
        },
        error: (error) => {
          Logging.info('response error',error)
          
          this.uiService.showSnackBar(error.message, 'okay', 5000);
        },
        complete: () => {},
      })
      .add(() => this.uiService.setLoading(false));
  };

  setAuthUser = (authResponse :AuthApiResponse) => {
    if (!authResponse.error) {
      this.token = authResponse.access_token;
      const expiresAt = authResponse.expires_in * this.SEC_TO_MILLISEC;
      
      if (expiresAt > 10000) {
        Logging.info('getUsers', expiresAt)
        this.setAuthTimer(expiresAt);
        this.userProfile = authResponse.user
        this.userOrganization = authResponse.org
        this.isAuth = true;
        localStorage.setItem('token', this.token);
        this.authStatusListener.next(true);
        this._router.navigate(['app']);
        this.userChanged.next(true);
      } else {
        this.logout()
      }
    }
    this.uiService.showSnackBar(authResponse.message, 'okay', 5000);
  }

  autoAuthUser() {
    const token = localStorage.getItem('token')!;
    Logging.info('autoAuthUser', token)
    
    if (!!token) {
      this.token = token;
      this.refreshUser({});
    }else{
      Logging.info('autoAuthUser', 'Error not found')
      return;
    }
  }

  private setAuthTimer(expiresInDurationMS: number) {
    Logging.warning('setAuthTimer', `Setting Auth Timer -> Session will expire at : ${new Date(new Date().getTime()+expiresInDurationMS)}`)
    this.tokenTimer = setTimeout(() => {
      this.logout();
      this.uiService.showSnackBar('Session Expired', 'Okay!', 5000);
    }, expiresInDurationMS);
  }

  logout() {
    this.token = undefined;
    this.isAuth = false;
    this.authStatusListener.next(false);
    clearTimeout(this.tokenTimer!);
    localStorage.removeItem('token');
    this.userChanged.next(true);
    this._router.navigate(['']).then(() => {
      window.location.reload();
    });
  }

  public live_device_by_imei = (
    _id: string
  ): string =>
  UrlHelper.createUrlWithPathVariables('vehicles/live', [_id]);

  // ManualRoster Trips API Start
  private manualRosteringTripCreateUrl = (): string =>
    UrlHelper.createUrl(ApiRoutes.ManualRosteringTrip);
  private BulkTripCreateUrl = (): string =>
    UrlHelper.createUrl(ApiRoutes.BulkTrip);
  private BulkRosterCreateUrl = (): string =>
    UrlHelper.createUrl(ApiRoutes.BulkRosterCreate);
  private BulkRosterDeleteUrl = (): string =>
    UrlHelper.createUrl(ApiRoutes.BulkRosterDelete);
  private manualRosteringTripByIdCreateUrl = (_id: string): string =>
    UrlHelper.createUrlWithPathVariables(ApiRoutes.ManualRosteringTrip, [_id]);
  private manualRosteringTripByIdUpdateVendorIdCreateUrl = (_id: string): string =>
    UrlHelper.createUrlWithPathVariables(ApiRoutes.ManualRosteringTripUpdateVendorID, [_id]);
  private manualRosteringTripByIdUpdateVehicleIdCreateUrl = (_id: string): string =>
    UrlHelper.createUrlWithPathVariables(ApiRoutes.ManualRosteringTripUpdateVehicleID, [_id]);
  private getVendorsOfComapanyId = (_id: string): string =>
    UrlHelper.createUrlWithPathVariables(ApiRoutes.vendorsByCompanyId, [_id]);
  private getGuardsOfComapanyId = (_id: string): string =>
    UrlHelper.createUrlWithPathVariables(ApiRoutes.guardsByCompanyId, [_id]);
  private TripsByCompanyId = (_id: string): string =>
    UrlHelper.createUrlWithPathVariables(ApiRoutes.TripsByCompanyId, [_id]);

    createTrip = (formValue: any): Observable<any> =>
    this.apiHttpService.post(this.manualRosteringTripCreateUrl(), formValue);
    
    createBulkTrip = (formValue: any): Observable<any> =>
    this.apiHttpService.post(this.BulkTripCreateUrl(), formValue);
    createBulkRoster = (formValue: any): Observable<any> =>
    this.apiHttpService.post(this.BulkRosterCreateUrl(), formValue);
    deleteBulkRoster = (formValue: any): Observable<any> =>
    this.apiHttpService.post(this.BulkRosterDeleteUrl(), formValue);
    
    editTrip = (formValue: any): Observable<any> =>
    this.apiHttpService.patch(
      this.manualRosteringTripByIdCreateUrl(formValue.id),
      formValue
      );

    updateVendorId = (formValue: any): Observable<any> =>{
      console.log("updateVendorId",formValue.value)
    return this.apiHttpService.patch(
      this.manualRosteringTripByIdUpdateVendorIdCreateUrl(formValue.value.id),
      formValue.value
      )};
    updateVehicleId = (formValue: any): Observable<any> =>{
      console.log("updateVendorId",formValue.value)
    return this.apiHttpService.patch(
      this.manualRosteringTripByIdUpdateVehicleIdCreateUrl(formValue.value.id),
      formValue.value
      )};
  getShiftGroupsById = (shiftGroupId: number): Observable<any> =>
    this.apiHttpService.get(
      UrlHelper.createUrlWithPathVariables(ApiRoutes.OrgShifts, [
        shiftGroupId,
      ])
    );
  
  changeEmployeeOrder = (formValue: any): Observable<any> =>
    this.apiHttpService.patch(
      UrlHelper.createUrlWithPathVariables(ApiRoutes.ChangeEmpOrder, [formValue.id]),
      formValue
    );
  
  getVendorsOfCompanyId = (companyId: any): Observable<any> =>
    this.apiHttpService.get(
      this.getVendorsOfComapanyId(companyId)
    );
  getGuardsOfCompanyId = (companyId: any): Observable<any> =>
    this.apiHttpService.get(
      this.getGuardsOfComapanyId(companyId)
    );

  private AllTrips = (companyId: string): Observable<any> =>
    this.apiHttpService.get(this.TripsByCompanyId(companyId));

  public all_manual_rostering_trips_changed = new BehaviorSubject(false);
  all_manual_rostering_trips: Trips[] = [];
  getAllManualRosterTrips(companyId: string) {
    this.uiService.setLoading(true);
    this.AllTrips(companyId)
      .subscribe({
        next: (value) => {
          Logging.info('getAllManualRosterTrips', value);
          if (value.error) {
            this.uiService.showSnackBar(value.message, 'okay', 5000);
          } else {
            this.all_manual_rostering_trips = value.data;
            this.all_manual_rostering_trips_changed.next(true);
          }
        },
        error: (error) => {
          Logging.error('getAllManualRosterTrips', error);
          this.uiService.showSnackBar(error.message, 'okay', 5000);
        },
        complete: () => {},
      })
      .add(() => this.uiService.setLoading(false));
  }


   // WMS Stop Start
   private manualRosteringStop = (): string =>
   UrlHelper.createUrl(ApiRoutes.ManualRosteringStops);
 private manualRosteringStopsCreateUrl = (): string =>
   UrlHelper.createUrl(ApiRoutes.ManualRosteringStops);
 private manualRosteringTripIdStopsWithCreatedStops = (tripId: number): string =>
   UrlHelper.createUrlWithPathVariables(
     ApiRoutes.ManualRosteringStopsWithTripStops,
     [tripId]
   );
 private manualRosteringtopsByCompanyIdAndDate = (companyId: number, date: string): string =>
   UrlHelper.createUrlWithPathVariables(
     ApiRoutes.StopsOfVendorByComapnyId,
     [companyId, date],
   );
 private mirrorTripId = (tripId: number): string =>
   UrlHelper.createUrlWithPathVariables(
     ApiRoutes.MirrorTrip,
     [tripId],
   );
 private stopsGroupByCompanyIdAndDate = (companyId: number, date: string): string =>
   UrlHelper.createUrlWithPathVariables(
     ApiRoutes.StopsGroupOfVendorByComapnyId,
     [companyId, date],
   );
 private stopsByCompanyIdTripIdAndDate = (tripId: number, companyId: number, date: string): string =>
   UrlHelper.createUrlWithPathVariables(
     ApiRoutes.StopsOfVendorByComapnyIdTripId,
     [tripId,companyId, date],
   );

   mirrorTripGet = (tripId: any): Observable<any> =>
    this.apiHttpService.get(
      this.mirrorTripId(tripId)
    );
    manualRosterCreatedStopsGet = (companyId: number, date: string): Observable<any> =>
    this.apiHttpService
     .get(this.manualRosteringtopsByCompanyIdAndDate(companyId, date));

 createStops = (formValue: any): Observable<any> =>
   this.apiHttpService.post(this.manualRosteringStopsCreateUrl(), formValue);

 public all_manual_rostering_created_stops_changed = new BehaviorSubject(false);
 all_manual_rostering_created_stops: Stops[] = [];
 getManualRosterCreatedStops(companyId: number, date: string) {
   this.uiService.setLoading(true);
   this.all_manual_rostering_created_stops = [];
   this.manualRosterCreatedStopsGet(companyId, date)
     .subscribe({
       next: (value) => {
         Logging.info('getManualRosterCreatedStops', value);
         if (value.error) {
           this.uiService.showSnackBar(value.message, 'okay', 5000);
         } else {
           this.all_manual_rostering_created_stops = value.data;
           this.all_manual_rostering_created_stops_changed.next(true);
         }
       },
       error: (error) => {
         Logging.error('getManualRosterCreatedStops', error);
         this.uiService.showSnackBar(error.message, 'okay', 5000);
       },
       complete: () => {},
     })
     .add(() => this.uiService.setLoading(false));
 }
 
 public group_of_created_stops_changed = new BehaviorSubject(false);
 group_of_created_stops: StopCluster[] = [];
 getGroupsCreatedStops(companyId: number, date: string) {
   this.uiService.setLoading(true);
   this.all_manual_rostering_created_stops = [];
   this.apiHttpService
     .get(this.stopsGroupByCompanyIdAndDate(companyId, date))
     .subscribe({
       next: (value) => {
         Logging.info('getGroupsCreatedStops', value);
         if (value.error) {
           this.uiService.showSnackBar(value.message, 'okay', 5000);
         } else {
           this.group_of_created_stops = value.data;
           this.group_of_created_stops_changed.next(true);
         }
       },
       error: (error) => {
         Logging.error('getGroupsCreatedStops', error);
         this.uiService.showSnackBar(error.message, 'okay', 5000);
       },
       complete: () => {},
     })
     .add(() => this.uiService.setLoading(false));
 }
 getManualRosterCreatedWithTripIdStops(tripId: number, companyId: number, date: string) {
   this.uiService.setLoading(true);
   this.apiHttpService
     .get(this.stopsByCompanyIdTripIdAndDate(tripId, companyId, date))
     .subscribe({
       next: (value) => {
         Logging.info('getManualRosterCreatedWithTripIdStops', value);
         if (value.error) {
           this.uiService.showSnackBar(value.message, 'okay', 5000);
         } else {
           this.all_manual_rostering_created_stops = value.data;
           this.all_manual_rostering_created_stops_changed.next(true);
         }
       },
       error: (error) => {
         Logging.error('getManualRosterCreatedStops', error);
         this.uiService.showSnackBar(error.message, 'okay', 5000);
       },
       complete: () => {},
     })
     .add(() => this.uiService.setLoading(false));
 }

 deleteTrips(company_id: number, trip_ids:string) {
    console.log('deleteTrips',company_id, trip_ids);
    return this.apiHttpService.post(UrlHelper.createUrl(ApiRoutes.BulkUploadTripsDelete), {
      company_id:company_id,
      trip_ids:trip_ids
    })
  }
 removeVehicleFromTrip(trip_id:string) {
    console.log('removeVehicleFromTrip', trip_id);
    return this.apiHttpService.post(UrlHelper.createUrl(ApiRoutes.RemoveVehicle), {
      trip_id:trip_id
    })
  }
 getAvailableVehicle(trip:any) {
    console.log('getAvailableVehicle', trip);
    return this.apiHttpService.post(UrlHelper.createUrl(ApiRoutes.AvailableVehicle), {
      trip_id:trip.id,
      vendor_id:trip.vendor_id,
      company_shift_id:trip.company_shift_id,
      shift_group_id:trip.shift_group_id,
      trip_start_date:trip.trip_start_date
    })
  }
}
