// import chalk from 'chalk';
import { environment } from 'src/environments/environment';

export default class Logging {
  public static log = (args: any) => this.info(args);
  public static info = (path: any, args?: any) => {
    if (environment.loggingI)
      console.log(
        // chalk.blue(
          `[${new Date().toLocaleString()}] [INFO]`,
          // ),
        path,
        args,
      );
  };
  public static warning = (path: any, args: any) => {
    if (environment.loggingW)
      console.log(
        `[${new Date().toLocaleString()}] [WARN]`,
        path,
        args,
      );
  };
  public static error = (path: any, args: any) => {
    if (environment.loggingE)
      console.log(
        `[${new Date().toLocaleString()}] [ERROR]`,
        path,
        args,
      );
  };
  public static success = (path: any, args: any) => {
    if (environment.loggingS)
      console.log(
        
        `[${new Date().toLocaleString()}] [SUCCESS]`,
        path,
        args
      );
  };
}
