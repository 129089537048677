import { UrlBuilder } from './url-builder';
import { environment } from 'src/environments/environment';
import { QueryStringParameters } from './query-string-parameters';

export class UrlHelper {
  static createUrl(action: string, baseUrl:string = environment.api_endpoint): string {
    const urlBuilder: UrlBuilder = new UrlBuilder(
      baseUrl,
      action
    );
    return urlBuilder.toString();
  }

  // URL WITH QUERY PARAMS
  static createUrlWithQueryParameters(
    action: string,
    queryStringHandler?: (queryStringParameters: QueryStringParameters) => void,
    baseUrl:string = environment.api_endpoint
  ): string {
    const urlBuilder: UrlBuilder = new UrlBuilder(
      baseUrl,
      action
    );
    // Push extra query string params
    if (queryStringHandler) {
      queryStringHandler(urlBuilder.queryString);
    }
    return urlBuilder.toString();
  }

  // URL WITH PATH VARIABLES
  static createUrlWithPathVariables(
    action: string,
    pathVariables: any[] = [],
    baseUrl:string = environment.api_endpoint
  ): string {
    let encodedPathVariablesUrl: string = '';
    // Push extra path variables
    for (const pathVariable of pathVariables) {
      if (pathVariable !== null) {
        encodedPathVariablesUrl += `/${encodeURIComponent(
          pathVariable.toString()
        )}`;
      }
    }
    const urlBuilder: UrlBuilder = new UrlBuilder(
      baseUrl,
      `${action}${encodedPathVariablesUrl}`
    );
    return urlBuilder.toString();
  }
}
