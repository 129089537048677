export enum WebFeatures {
  correctKms = 'correct_kms',
  adminNoShowEdit = 'admin_no_show_edit',
}
export enum AuthType {
  Email = 'email',
  Mobile = 'mobile',
  // Password = 'password'
}
export enum UserType {
  SystemAdmin = 'System Admin',
  VendorAdmin = 'Vendor Admin',
  CompanyAdmin = 'Company Admin',
  Employee = 'Employee',
  Driver = 'Driver',
  Guard = 'Guard',
  HelpDeskSecurity = 'Security HelpDesk',
  GateKeeper = 'Gate Keeper',
}
export enum ComplianceTypes {
  Vehicle = 'Vehicle',
  Driver = 'Driver',
  Guard = 'Guard',
}
export enum TripBookingTypes {
  OnCall = 'On-Call',
}
export enum OrgType {
  System = 'System',
  Vendor = 'Vendor',
  Company = 'Company',
}
export enum Genders {
  MALE = 'Male',
  FEMALE = 'Female',
  Transgender = 'Transgender',
  PreferNotToSay = 'Prefer Not to Say',
}
export enum ShiftModelType {
  FIXED = 'fixed',
  CUSTOM = 'custom',
}
export enum EmployeeStopTypes {
  NODAL = 'nodal',
  CUSTOM = 'custom',
}

export enum ShiftRosterType {
  LOGIN = 'Login',
  LOGOUT = 'Logout',
  ADHOC = 'Ad-Hoc',
}

export enum StopStatus {
  created = 'Created',
  assigned = 'Assigned',
  completed = 'Completed',
}
export enum TripStatus {
  created = 'Created',
  assigned = 'Assigned',
  running = 'Running',
  completed = 'Completed',
}
export enum ShiftTypes {
  fixed = 'fixed',
  custom = 'custom',
}
// ['System Admin', 'Vendor Admin', 'Company Admin', 'Employee', 'Driver', 'Supervisor']);
