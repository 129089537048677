import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiEndpointsService } from '../core/services/api-endpoints.service';
import { UIService } from '../core/services/ui.service';
// import { AppConstants } from '../core/shared/app-constants';
import { CanTransferFeatureFlagService } from '../core/shared/canTransferFeatureFlag.service';
import { FeatureFlagService } from '../core/shared/feature-flag.service';
import { User } from '../core/shared/interfaces';
import { UserType } from '../core/shared/types';
// import { ManualRosteringRoleTypes, OrgTypes, RolesTypes, WmsRoleTypes } from '../core/shared/OrgTypes';
// import { UserType } from '../core/shared/UserType';

@Component({
  selector: 'app-app-main',
  templateUrl: './app-main.component.html',
  styleUrls: ['./app-main.component.css'],
})
export class AppMainComponent implements OnInit, AfterViewInit, OnDestroy {
  AppVer = environment.version;
  // navList = AppConstants.FEATURE_GROUPS;

  private authListenerSubs: Subscription;
  private userChangedSubs!: Subscription;
  isAuthenticated: Boolean = false;

  authUser: User | undefined;
  // userfirstName: string | undefined = '';
  // loggedUserType: string | undefined = '';
  // loggedUserRole: string | undefined = '';
  // userEmail: string | undefined = '';
  // userCompanyName: string | undefined = '';

  public get innerWidth() {
    return window.innerWidth;
  }

  constructor(
    private apiEndpointsService: ApiEndpointsService,
    private uiService: UIService,
  ) {
    this.authListenerSubs = this.apiEndpointsService
      .getAuthStatusListener()
      .subscribe((isAuthenticated) => {
        this.isAuthenticated = isAuthenticated;
      });
  }

  isSysAdm = false;
  isVendorAdmin = false;
  isCompanyAdmin = false;
  isCompanyEmployee = false;
  isCompanyHelpDeskSecurity = false;

  ngOnInit(): void {

    this.isSysAdm = this.apiEndpointsService.getIsUserType(UserType.SystemAdmin)
    this.isVendorAdmin = this.apiEndpointsService.getIsUserType(UserType.VendorAdmin)
    this.isCompanyAdmin = this.apiEndpointsService.getIsUserType(UserType.CompanyAdmin)
    this.isCompanyEmployee = this.apiEndpointsService.getIsUserType(UserType.Employee)
    this.isCompanyHelpDeskSecurity = this.apiEndpointsService.getIsUserType(UserType.HelpDeskSecurity)
  }

  ngAfterViewInit(): void {
    this.userChangedSubs = this.apiEndpointsService.userChanged.subscribe(
      (isUserChanged: boolean) => {
        if (isUserChanged) {
          this.authUser = this.apiEndpointsService.userProfile;
        }
      }
    );
  }

  ngOnDestroy(): void {
    // Logging.info('appcmp destroyed')
    this.authListenerSubs.unsubscribe();
    this.userChangedSubs.unsubscribe();
  }

  logout() {
    this.apiEndpointsService.logout();
  }
}
