export class ApiRoutes
{
    static readonly USER = 'users';
    static readonly UserByCompany = 'users/company';
    static readonly guardsByCompanyId = "users/guards";
    static readonly guardsOfCompanyGroup = "users/org/guards";
    static readonly ORGANIZATION = 'orgs';
    static readonly STOPS = 'stops';
    static readonly SHIFTGROUPS = 'orgs/shiftgroup';
    static readonly VEHICLE = 'vehicles';
    static readonly RemoveVehicle = `${ApiRoutes.VEHICLE}/unassign`;
    static readonly AvailableVehicle = `${ApiRoutes.VEHICLE}/available`;
    static readonly SUPPORTTICKETS = 'tickets';
    static readonly REPORTS = 'reports';
    static readonly DailyCharts = 'charts/daily/company';
    
    static readonly EMPLOYEES = 'employees';
    static readonly EmployeeStops = `${ApiRoutes.EMPLOYEES}/stops`;
    static readonly OrgShifts = `${ApiRoutes.ORGANIZATION}/shifts`;
    
    static readonly CompanyShiftGroup = 'shiftgroups';
    static readonly CompanyShift = 'companyshifts';
    static readonly CompanyShiftByCompany = `${ApiRoutes.CompanyShift}/company`;
    
    static readonly NodalPoints = 'nodal';
    static readonly NodalPointsInRoute = 'nodal/route';
    static readonly NodalPointsByCompanyId = 'nodal/company';
    
    static readonly NodalRoute = 'route';
    static readonly NodalRoutesByCompanyId = 'route/company';

    // Auth Related
    static readonly SendOTP = `sendOtp`;
    static readonly verifyOTP = `verifyOtp`;
    static readonly refreshUser = `${ApiRoutes.USER}/refresh`;
    static readonly getAllSupportTickets = `${ApiRoutes.SUPPORTTICKETS}/getall`;
    static readonly closeSupportTicket = `${ApiRoutes.SUPPORTTICKETS}/close`;
    static readonly getSupportTicketsHistory = `${ApiRoutes.SUPPORTTICKETS}/gethistory`;
    static readonly createSupportTicketsHistory = `${ApiRoutes.SUPPORTTICKETS}/history`;
    
    // Manual Rostering Related
    static readonly ManualRosteringTrip = "trips";
    static readonly ManualRosteringTripUpdateVendorID = "trips/updateVendorId";
    static readonly ManualRosteringTripUpdateVehicleID = "trips/updateVehicleId";
    static readonly BulkTrip = "trips/bulk";
    static readonly ChangeEmpOrder = "trips/changeorder";
    static readonly BulkUploadStopsCreateTrip = "trips/upload/stops";
    static readonly BulkUploadStopsFromCSV = "trips/upload/csvStops";
    static readonly BulkUploadTripsDelete = "trips/delete";
    static readonly BulkRosterCreate = "trips/roster/add";
    static readonly BulkRosterDelete = "trips/roster/delete";
    static readonly MirrorTrip = "trips/mirror";
    static readonly Drivers = "drivers";
    static readonly Dashboard = "drivers/trips/dashboard";
    static readonly Roster = "drivers/roster/dashboard";
    static readonly BookingsTrips = "drivers/roster/bookings/dashboard";
    static readonly FilteredRoster = "drivers/roster/dashboard/filtered";
    static readonly RosterShifts = "drivers/roster/shifts";
    static readonly RosterView = "drivers/roster-view/dashboard";
    static readonly FilteredRosterView = "drivers/roster-view/dashboard/filtered";
    static readonly RosterShiftsView = "drivers/roster-view/shifts";
    static readonly CompanyTripsView = "drivers/trips/company";
    static readonly TripsPatchGuardId = "trips/guard";
    static readonly TripsPatchCorrectKms = "trips/correct_kms";
    static readonly TripsByCompanyId = "trips/company";
    static readonly vendorsByCompanyId = "trips/vendors";
    static readonly ManualRosteringStops = "stops";
    static readonly StopsOfVendorByComapnyId = "stops/vendor";
    static readonly MarkNoShowStop = "stops/noshow";
    static readonly StopsGroupOfVendorByComapnyId = "stops/clusters/vendor";
    static readonly StopsOfVendorByComapnyIdTripId = "stops/vendor/trips";
    static readonly UpdateEtaByStopId = "stops/update/eta";
    static readonly ManualRosteringStopsWithTripStops = `${ApiRoutes.ManualRosteringStops}/withTrip`;

    // Compliance Mng System
    static readonly IDENTITY = 'identities';
    static readonly COMPLIANCE = 'compliance';
    static readonly complianceTypes = 'compliance/list';
    static readonly createVehicleCompliance = 'compliance/vehicle';
    static readonly createDriverCompliance = 'compliance/driver';
    static readonly readCompliancesByImId = 'compliance/readByImId';
    static readonly VALIDITY = 'validity';
    static readonly validitiesReadByComplianceId = 'validity/readByComplianceId';
    static readonly validitiesReadLatestByIdentityId = 'validity/latest/readByIdentityId';
    static readonly tripFeedback = 'trips/feedback';  
};