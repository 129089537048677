import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApiEndpointsService } from '../core/services/api-endpoints.service';
import { UIService } from '../core/services/ui.service';
import { AuthType } from '../core/shared/types';
import { LoginModel } from '../core/shared/interfaces';
import Logging from '../core/services/Logging';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  isOtpSent: boolean = false;
  AppVer = environment.version;

  authTypeEmail = AuthType.Email;
  @ViewChild('emailFormField') emailFormField!: MatFormField;
  authTypeMobile = AuthType.Mobile;

  userLogin: LoginModel = {
    type: AuthType.Email,
    email: undefined,
    mobile: undefined,
    otp: undefined,
  };
  constructor(
    private apiEndpointsService: ApiEndpointsService,
    private _router: Router,
    private route: ActivatedRoute,
    private uiService: UIService
  ) {}

  ngOnInit(): void {}

  sendOtp(form: NgForm) {
    if (form.invalid) {
      return;
    }
    Logging.info('sendOtp',form)
    
    this.userLogin.otp = undefined;
    
    this.uiService.setLoading(true);
    this.apiEndpointsService.sendOtp(form.value).subscribe({
        next: (value) => {
          Logging.info('response',value)
          
          if (value.error) {
          } else {
            this.isOtpSent = true;
          }
          this.uiService.showSnackBar(value.message, 'okay', 5000);
        },
        error: (error) => {
          Logging.error('sendOtp error', error);
          this.uiService.showSnackBar(error.message, 'okay', 5000);
        },
        complete: () => {},
      })
      .add(() => this.uiService.setLoading(false));
  }

  verifyOtp(form: NgForm) {
    Logging.info('verify', form.value);
    if (form.invalid) {
      return;
    }
    this.apiEndpointsService.verifyOtp(form.value);
  }

  resetForm(form: NgForm) {
    // form.resetForm();
    // form.reset();
    this.userLogin = {
      type: AuthType.Email,
      email: undefined,
      mobile: undefined,
      otp: undefined,
    };

    this.isOtpSent = false;
  }
}
