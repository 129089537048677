import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiEndpointsService } from './core/services/api-endpoints.service';
import { UIService } from './core/services/ui.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'global-vts-web';

  isLoading = false;

  private loadingSubs!: Subscription;

  constructor(private uiService: UIService, private apiEndpointsService: ApiEndpointsService,httpClient: HttpClient
  ) {
    this.loadingSubs = this.uiService.loadingStateChanged$.subscribe(
      isLoading => this.isLoading = isLoading
    )
  }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.apiEndpointsService.autoAuthUser();
  }

  ngOnDestroy() {
    this.loadingSubs.unsubscribe();
  }
}
