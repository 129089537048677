import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ApiEndpointsService } from "../services/api-endpoints.service";
import { ApiHttpService } from "../services/api-http.service";

@Injectable()
export class FeatureFlagService {

    private _featureFlags: Array<string> = []; // A list of all features turned ON
    private _initialized = false;
    public isFlagsUpdated = new BehaviorSubject(false);

    constructor(private apiHttpService: ApiHttpService,
        private apiEndpointsService: ApiEndpointsService) { }

    featureOff(featureName: string) {
        return !this.featureOn(featureName);
    }

    featureOn(featureName: string) {
        if (!featureName) {
            return true;
        }
        // Find the feature flag that is turned on
        return this._featureFlags && !!this._featureFlags.find(feature => {
            return feature === featureName;
        });
        // if feature not found, default to turned off
    }

    get initialized() {
        return this._initialized;
    }
 
    // This method is called once and a list of features is stored in memory
    initialize(features : string []) {
        this._featureFlags = [];
        return new Promise((resolve, reject) => {
            this._featureFlags = this._featureFlags.concat(features);
            this._initialized = true;
            this.isFlagsUpdated.next(true);
            resolve(this._featureFlags);
        });
    }
}