import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { ApiEndpointsService } from "./core/services/api-endpoints.service";

@Injectable()
export class AuthGuard implements CanActivate {
    
    constructor(private apiEndPointService:ApiEndpointsService, private router:Router) {
        
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const isAuth = this.apiEndPointService.getIsAuth();
        if (!isAuth) {
            this.router.navigate(['']);
        }
        return isAuth;
    }
    
}