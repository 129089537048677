<div>
    <mat-toolbar #matToolbar color="primary" style="height: var(--toolbarHeight);" class="mat-elevation-z8">
      <div>
        <button mat-icon-button (click)="sideNav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      <!-- {{userCompanyName?userCompanyName:'Global VTS'}}  -->
      <div> {{authUser?.type}} | {{authUser?.email}} </div>
      <div style="flex: 1 1 0%;box-sizing: border-box;"></div>
      <div>
        <button mat-button [matMenuTriggerFor]="logoutMenu">
          <mat-icon>{{isSysAdm? 'supervised_user_circle':'account_circle'}}</mat-icon>
        </button>
        <mat-menu #logoutMenu="matMenu">
          <ng-template matMenuContent>
            <mat-list style="text-align: center;">
              <mat-list-item>
                <div mat-line mat-card-title>{{authUser?.firstname}}</div>
                <div mat-line mat-card-subtitle> {{authUser?.email}} </div>
                <!-- <div mat-line>{{userCompanyName}}</div> -->
              </mat-list-item>
              <mat-divider></mat-divider>
              <mat-list-item>
                <div mat-line>
                  <button mat-stroked-button (click)="logout()">
                    <mat-icon>logout</mat-icon>&nbsp;&nbsp;&nbsp;Sign out
                  </button>
                </div>
              </mat-list-item>
  
              <mat-divider></mat-divider>
            </mat-list>
          </ng-template>
        </mat-menu>
      </div>
    </mat-toolbar>
    <mat-sidenav-container style="height: calc(100vh - var(--toolbarHeight));top: var(--toolbarHeight);">
      <mat-sidenav #sideNav [mode]="innerWidth >= 768?'side':'over'" role="navigation" [opened]="innerWidth >= 768?true:false" >
          <div class="navColumn">
            <div class="child-height-strech">
              <mat-nav-list>
                <a mat-list-item
                      routerLinkActive="activeLink" (click)="innerWidth <= 768?sideNav.toggle():'';"
                      routerLink="./dashboard">
                      <mat-icon>dashboard</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;Dashboard
                  </a>
                <a mat-list-item *ngIf="isVendorAdmin || isCompanyAdmin"
                      routerLinkActive="activeLink" (click)="innerWidth <= 768?sideNav.toggle():'';"
                      routerLink="./feedback">
                      <mat-icon>support_agent</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;Help Support
                  </a>
                <a mat-list-item *ngIf="isVendorAdmin || isCompanyAdmin || isSysAdm"
                      routerLinkActive="activeLink" (click)="innerWidth <= 768?sideNav.toggle():'';"
                      routerLink="./users">
                      <mat-icon>people</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;Accounts
                  </a>
                <a mat-list-item  *ngIf="isSysAdm"
                      routerLinkActive="activeLink" (click)="innerWidth <= 768?sideNav.toggle():'';"
                      routerLink="./orgs">
                      <mat-icon>corporate_fare</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;Organizations
                </a>
                <a mat-list-item  *ngIf="isVendorAdmin || isCompanyAdmin "
                      routerLinkActive="activeLink" (click)="innerWidth <= 768?sideNav.toggle():'';"
                      routerLink="./compliance">
                      <mat-icon>corporate_fare</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;Compliance
                </a>
                <a mat-list-item *ngIf="isVendorAdmin || isCompanyAdmin || isSysAdm"
                      routerLinkActive="activeLink" (click)="innerWidth <= 768?sideNav.toggle():'';"
                      routerLink="./vehicles" [routerLinkActiveOptions]="{ 'exact': true }" >
                      <mat-icon>bike_scooter</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;Vehicles
                  </a>
                <a mat-list-item *ngIf="isVendorAdmin || isCompanyAdmin || isSysAdm || isCompanyHelpDeskSecurity"
                      routerLinkActive="activeLink" (click)="innerWidth <= 768?sideNav.toggle():'';"
                      routerLink="./vehicles/map-history">
                      <mat-icon>history</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;Map history
                  </a>
                <a mat-list-item *ngIf="isVendorAdmin || isCompanyAdmin || isSysAdm || isCompanyHelpDeskSecurity"
                      routerLinkActive="activeLink" (click)="innerWidth <= 768?sideNav.toggle():'';"
                      routerLink="./vehicles/live-track">
                      <mat-icon>near_me</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;Group Track
                  </a>
                <a mat-list-item  *ngIf="isVendorAdmin" 
                      routerLinkActive="activeLink" (click)="innerWidth <= 768?sideNav.toggle():'';"
                      routerLink="./trips/map-vendor">
                      <mat-icon>calendar_month</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;Trips
                  </a>
                <a mat-list-item  *ngIf="isCompanyAdmin" 
                      routerLinkActive="activeLink" (click)="innerWidth <= 768?sideNav.toggle():'';"
                      routerLink="./trips/map-company">
                      <mat-icon>calendar_month</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;Trips
                  </a>
                <!-- <a mat-list-item  *ngIf="isVendorAdmin"
                      routerLinkActive="activeLink" (click)="innerWidth <= 768?sideNav.toggle():'';"
                      routerLink="./trips/roster-groups">
                      <mat-icon>diversity_2</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;Roster Groups
                  </a> -->
                <a mat-list-item  *ngIf="isCompanyAdmin || isVendorAdmin"
                      routerLinkActive="activeLink" (click)="innerWidth <= 768?sideNav.toggle():'';"
                      routerLink="./trips/upload-roster">
                      <mat-icon>cloud_upload</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;Upload Groups
                  </a>
                <a mat-list-item  *ngIf="isCompanyEmployee"
                      routerLinkActive="activeLink" (click)="innerWidth <= 768?sideNav.toggle():'';"
                      routerLink="./employee/trips">
                      <mat-icon>calendar_month</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;Add Roster
                  </a>

                  <!-- Trips View For Company Admin -->
                <!-- <a mat-list-item  *ngIf="isCompanyAdmin || isVendorAdmin" 
                      routerLinkActive="activeLink" (click)="innerWidth <= 768?sideNav.toggle():'';"
                      routerLink="./company/trips_view">
                      <mat-icon>route</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;Trip Route
                  </a>  -->
                <a mat-list-item  *ngIf="isCompanyAdmin || isVendorAdmin || isCompanyHelpDeskSecurity" 
                      routerLinkActive="activeLink" (click)="innerWidth <= 768?sideNav.toggle():'';"
                      routerLink="./company/roster">
                      <mat-icon>calendar_month</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;Rosters
                  </a>
                <!-- <a mat-list-item  *ngIf="isCompanyAdmin || isVendorAdmin || isCompanyHelpDeskSecurity" 
                      routerLinkActive="activeLink" (click)="innerWidth <= 768?sideNav.toggle():'';"
                      routerLink="./bookings">
                      <mat-icon>calendar_month</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;Bookings
                  </a> -->
                <a mat-list-item  *ngIf="isCompanyAdmin || isVendorAdmin" 
                      routerLinkActive="activeLink" (click)="innerWidth <= 768?sideNav.toggle():'';"
                      routerLink="./company/roster-view">
                      <mat-icon>view_module</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;Rosters View
                  </a>
                <a mat-list-item  *ngIf="isCompanyAdmin || isVendorAdmin" 
                      routerLinkActive="activeLink" (click)="innerWidth <= 768?sideNav.toggle():'';"
                      routerLink="./company/roster-add">
                      <mat-icon>free_cancellation</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;Add Rosters
                  </a>
                <a mat-list-item  *ngIf="isCompanyAdmin || isVendorAdmin || isSysAdm" 
                      routerLinkActive="activeLink" (click)="innerWidth <= 768?sideNav.toggle():'';"
                      routerLink="./reports">
                      <mat-icon>receipt_long</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;Reports
                  </a>

                  <a mat-list-item  *ngIf="isCompanyAdmin || isVendorAdmin"
                      routerLinkActive="activeLink" (click)="innerWidth <= 768?sideNav.toggle():'';"
                      routerLink="./uploads">
                      <mat-icon>upload_file</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;File Uploads
                  </a>
                  <!-- <a mat-list-item  *ngIf="isSysAdm || isCompanyAdmin" -->
                  <a mat-list-item  *ngIf="isSysAdm"
                      routerLinkActive="activeLink" (click)="innerWidth <= 768?sideNav.toggle():'';"
                      routerLink="./nodalPoints">
                      <mat-icon>person_pin_circle</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;Nodal Points
                  </a>
                  <!-- <a mat-list-item  *ngIf="isSysAdm || isCompanyAdmin" -->
                  <a mat-list-item  *ngIf="isSysAdm"
                      routerLinkActive="activeLink" (click)="innerWidth <= 768?sideNav.toggle():'';"
                      routerLink="./nodalRoutes">
                      <mat-icon>route</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;Nodal Routes
                  </a>
                  <a mat-list-item  *ngIf="isSysAdm"
                      routerLinkActive="activeLink" (click)="innerWidth <= 768?sideNav.toggle():'';"
                      routerLink="./shiftGroups">
                      <mat-icon>schedule</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;Shifts Groups
                  </a>
                  <a mat-list-item  *ngIf="isCompanyAdmin || isVendorAdmin || isSysAdm"
                      routerLinkActive="activeLink" (click)="innerWidth <= 768?sideNav.toggle():'';"
                      routerLink="./companyShifts">
                      <mat-icon>work_history</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;Company Shifts
                  </a>
              </mat-nav-list>
            </div>
            <div style="text-align:-webkit-center;padding: 8px;">App Ver. {{AppVer}}</div>
            <div style="text-align:-webkit-center;padding: 8px;">
              <img mat-card-image draggable="false" class="brandLogo" src="./assets/CosmicaMain.svg" alt="Cosmica Logo">
            </div>
          </div>
      </mat-sidenav>
      <mat-sidenav-content>
        <main style="height: calc(100vh - var(--toolbarHeight));">
          <router-outlet></router-outlet>
        </main>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>