import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppMainComponent } from './app-main/app-main.component';
import { AuthGuard } from './auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: LoginComponent },
  { path: 'app', component: AppMainComponent,
  children: [
      { path: '', component: DashboardComponent },
      { path: 'dashboard', component: DashboardComponent },
      // { path: 'feedback', component: FeedbackComponent },
      // { path: 'history', component: TicketHistoryComponent },
      { path: 'feedback', loadChildren: () => import('./help-support/help-support.module').then(m => m.HelpSupportModule) },
      { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
      { path: 'employee/trips', loadChildren: () => import('./users-trips/users-trips.module').then(m => m.UsersTripsModule) },
      { path: 'trips', loadChildren: () => import('./trips/trips.module').then(m => m.TripsModule) },
      { path: 'orgs', loadChildren: () => import('./orgs/orgs.module').then(m => m.OrgsModule) },
      { path: 'compliance', loadChildren: () => import('./compliance/compliance.module').then(m => m.ComplianceModule) },
      { path: 'vehicles', loadChildren: () => import('./vehicles/vehicles.module').then(m => m.VehiclesModule) },
      { path: 'company', loadChildren: () => import('./company/company.module').then(m => m.CompanyModule) },
      { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) },
      { path: 'uploads', loadChildren: () => import('./uploads/uploads.module').then(m => m.UploadsModule) },
      { path: 'shiftGroups', loadChildren: () => import('./company-shifts-group/company-shifts-group.module').then(m => m.CompanyShiftsGroupModule) },
      { path: 'companyShifts', loadChildren: () => import('./company-shifts/company-shifts.module').then(m => m.CompanyShiftsModule) },
      { path: 'nodalPoints', loadChildren: () => import('./nodal-points/nodal-points.module').then(m => m.NodalPointsModule) },
      { path: 'nodalRoutes', loadChildren: () => import('./nodal-routes/nodal-routes.module').then(m => m.NodalRoutesModule) },
      // { path: 'bookings', title: "bookings", loadComponent:()=> import ('./bookings/bookings.component').then(m => m.BookingsComponent) },
    ],
    canActivate: [AuthGuard]
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
