import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { ApiEndpointsService } from "./api-endpoints.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    
    constructor(private apiEndpointsService:ApiEndpointsService){}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const authToken = this.apiEndpointsService.getToken();
        const authReq = req.clone({
            headers: req.headers.set('Authorization',"Bearer "+authToken!)
        });
        return next.handle(authReq);
    }
    
}