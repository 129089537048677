<div style="display: flex;height: 100%;justify-content: space-around;align-items: center;background-color: #336fa7;">
    <form #loginForm="ngForm" (ngSubmit)="isOtpSent?verifyOtp(loginForm):sendOtp(loginForm)">
        <div style="text-align: center;">
            
            <mat-button-toggle-group *ngIf="!isOtpSent; else typeInput" name="type" aria-label="Auth Type" [(ngModel)]="userLogin.type" >
                <mat-button-toggle [value]="authTypeEmail">
                    <mat-icon [fontSet]="userLogin.type == authTypeEmail?'material-icons':'material-icons-outlined'">email</mat-icon>
                    <!-- Email -->
                    &nbsp;&nbsp;
                    {{userLogin.type == authTypeEmail?'Email':''}}
                </mat-button-toggle>
                <mat-button-toggle [value]="authTypeMobile">
                    <mat-icon [fontSet]="userLogin.type == authTypeMobile?'material-icons':'material-icons-outlined'">textsms</mat-icon>
                    <!-- Mobile -->
                    &nbsp;&nbsp;
                    {{userLogin.type == authTypeMobile?'Text SMS':''}}
                </mat-button-toggle>
            </mat-button-toggle-group>
            <ng-template #typeInput>
                <input  type="hidden" name="type" [(ngModel)]="userLogin.type">
            </ng-template>
            <br>
            <br>
            <mat-card class="mat-elevation-z8">
                <mat-card-content>
                    <br>
                    <div [ngSwitch]="userLogin.type">
                        <mat-form-field #emailFormField [appearance]="isOtpSent?'fill':'outline'" *ngSwitchCase="authTypeEmail">
                            <mat-label>Email</mat-label>
                            <input matInput placeholder="Email" name="email" type="email" autocomplete="off" required email [(ngModel)]="userLogin.email" [readonly]="isOtpSent"
                                #emailInput="ngModel">
                            <mat-hint>Enter Email Address</mat-hint>
                            <mat-error *ngIf="emailInput.invalid">Please enter a valid email address.</mat-error>
                        </mat-form-field>
                        <mat-form-field [appearance]="isOtpSent?'fill':'outline'" *ngSwitchCase="authTypeMobile">
                            <mat-label>Mobile</mat-label>
                            <input #telInput="ngModel"  minlength="10" maxlength="10" autocomplete="off" pattern="^([1-9][0-9]*)$" matInput placeholder="Mobile Number" type="tel" [readonly]="isOtpSent"
                                required name="mobile" [(ngModel)]="userLogin.mobile">
                            <mat-hint>Enter 10 Digit Mobile Number</mat-hint>
                            <!-- <mat-error *ngIf="telInput.hasError('required')">Mobile No. is required.</mat-error> -->
                            <mat-error *ngIf="telInput.hasError('minlength')">Mobile No. is invalid.</mat-error>
                            <mat-error *ngIf="telInput.hasError('pattern')">Only Numbers can be filled.</mat-error>
                        </mat-form-field>
                    </div>
                    
                    <br *ngIf="isOtpSent">
                    <mat-form-field [appearance]="isOtpSent?'fill':'outline'" *ngIf="isOtpSent">
                        <mat-label>OTP</mat-label>
                        <input #otpInput="ngModel"  minlength="6" maxlength="6" autocomplete="off" pattern="^(0|[1-9][0-9]*)$" matInput placeholder="OTP" type="tel"
                            required name="otp" [(ngModel)]="userLogin.otp">
                        <mat-hint>Enter 6 Digit OTP</mat-hint>
                        <mat-error *ngIf="otpInput.hasError('required')">OTP is required.</mat-error>
                        <mat-error *ngIf="otpInput.hasError('minlength')">OTP is invalid.</mat-error>
                        <mat-error *ngIf="otpInput.hasError('pattern')">Only Numbers can be filled.</mat-error>
                    </mat-form-field>
                </mat-card-content>
                <mat-card-actions style="display: flex;justify-content: space-evenly;flex-wrap: nowrap;">
                    <button *ngIf="isOtpSent" mat-raised-button color="warn" type="button" (click)="resetForm(loginForm)">RESET</button>
                    <button *ngIf="!isOtpSent" mat-raised-button color="primary" type="submit" [disabled]="loginForm.invalid">Send OTP</button>
                    <button *ngIf="isOtpSent" mat-raised-button color="primary" type="submit" [disabled]="loginForm.invalid">Verify OTP</button>
                </mat-card-actions>
            </mat-card>
        </div>
    </form>
    <!-- <div style="text-align:-webkit-center;padding: 8px;">App Ver. {{AppVer}}</div> -->
            
</div>
<div style="text-align:-webkit-center;width: 100%;position: fixed;bottom: 0px;">
    <div style="text-align:-webkit-center;padding: 8px;color: floralwhite;">App Ver. {{AppVer}}</div>
    <img width="200px" draggable="false" class="brandLogo" src="./assets/CosmicaMain.svg" alt="Cosmica Logo">
</div>