import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RemoveCanTransferIfFeatureOffDirective } from "./remove-can-transfer-feature-off.directive";
import { RemoveCanTransferIfFeatureOnDirective } from "./remove-can-transfer-feature-on.directive";
import { MyRemoveIfFeatureOffDirective } from "./remove-if-feature-off.directive";
import { MyRemoveIfFeatureOnDirective } from "./remove-if-feature-on.directive";

@NgModule({
    imports: [CommonModule],
    declarations: [
        MyRemoveIfFeatureOnDirective,
        MyRemoveIfFeatureOffDirective,

        RemoveCanTransferIfFeatureOffDirective,
        RemoveCanTransferIfFeatureOnDirective,
    ],
    exports: [
        MyRemoveIfFeatureOnDirective,
        MyRemoveIfFeatureOffDirective,

        RemoveCanTransferIfFeatureOffDirective,
        RemoveCanTransferIfFeatureOnDirective,
    ]
  })
  
  export class DirectivesModule {}