import { Injectable, NgZone } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subject } from "rxjs";

@Injectable()
export class UIService {
    constructor(
        private _snackbar:MatSnackBar,
    ) {}

    private loadingStateChanged = new Subject<boolean>();
    loadingStateChanged$ = this.loadingStateChanged.asObservable();
    showSnackBar(msg: string, action: string | undefined, duration: any){
        this._snackbar.open(msg,action,{duration: duration, verticalPosition: 'top', horizontalPosition:'start'});
    }

    private emiHeaderTitleChangeSource = new Subject<string>();
    headerTitleChangeEmitted$ = this.emiHeaderTitleChangeSource.asObservable();

    emitHeaderTitleChange(changeHeadTitle:string){
        this.emiHeaderTitleChangeSource.next(changeHeadTitle);
    }

    setLoading(isLoading:boolean){
        this.loadingStateChanged.next(isLoading);
    }

}