<section style="height: 100%;display: flex;flex-direction: column;">

  <div class="filterRow">
    <mat-form-field  [appearance]="'outline'" style="min-width:215px; margin-left: 12px;">
      <mat-label>Select Company</mat-label>
      <mat-select (selectionChange)="fetchDate()" [(ngModel)]="selectedCompany">
          <mat-option disabled>
            <input #searchCompany matInput (keyup)="filteredCompanies = selectFilter(searchCompany.value,companies,'brand_name')" placeholder="Search">
        </mat-option>
          <mat-option *ngFor="let company of filteredCompanies" [value]="company.id">
              {{company.brand_name}}
          </mat-option>
      </mat-select>
  </mat-form-field>

  <mat-form-field style="width:150px; margin-left: 12px;" appearance="outline">
    <mat-label>Choose Start Date</mat-label>
    <input matInput type="date" [ngModel]="selectedStartDate | date:'yyyy-MM-dd'" (ngModelChange)="selectedStartDate = $event"
    (input)="fetchDate()" (dateChange)="fetchDate()">
  </mat-form-field>

  <mat-form-field style="width:150px; margin-left: 12px;" appearance="outline">
    <mat-label>Choose End Date</mat-label>
    <input matInput type="date" [ngModel]="selectedEndDate | date:'yyyy-MM-dd'" (ngModelChange)="selectedEndDate = $event"
    (input)="fetchDate()" (dateChange)="fetchDate()">
  </mat-form-field>
</div>

<!-- <div #chartContainer></div> -->
<!-- <div  style="flex-grow: 1;display: flex;justify-content: space-around;align-content: space-around;flex-wrap: wrap;overflow: scroll;"> -->
<div *ngIf="!!selectedCompany;else selectCompany" style="flex-grow: 1;display: flex;justify-content: space-around;align-content: space-around;flex-wrap: wrap;">
    <google-chart *ngFor="let chart of charts" style="margin: 4px;"  class="mat-elevation-z8 roundedTable"
    [title]="chart.title"
    [type]="chart.type"
    [columns]="chart.columnNames"
    [data]="chart.data"
    [options]="chart.options"
    >
  </google-chart>
</div>
<ng-template #selectCompany>
  <div style="flex-grow: 1;display: flex;align-items: center;justify-content: center;"><h1>Please Select Company</h1></div>
</ng-template>
</section>
<!-- [columns]="chart.columnNames" -->