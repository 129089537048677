import { Pipe, PipeTransform } from '@angular/core';
import { Genders } from './types';

@Pipe({
  name: 'timeToDate',
})
export class TimeToDatePipe implements PipeTransform {
  transform(time: string, selectedDate?: Date, subtractTime?: number): Date {
    const date = selectedDate ? new Date(selectedDate) : new Date();
    const parts = time.split(':');
    date.setHours(parseInt(parts[0], 10));
    date.setMinutes(parseInt(parts[1], 10));
    date.setSeconds(parseInt(parts[2], 10));

    if (subtractTime) {
      // const subtractionParts = subtractTime.split(':');
      // date.setHours(date.getHours() - parseInt(subtractionParts[0], 10));
      // date.setMinutes(date.getMinutes() - parseInt(subtractionParts[1], 10));
      // date.setSeconds(date.getSeconds() - parseInt(subtractionParts[2], 10));
      date.setMinutes(date.getMinutes() - subtractTime);
    }
    return date;
  }
}

@Pipe({
  name: 'isDateLessThanCrtTime',
})
export class DateLessThanCrtTimePipe implements PipeTransform {
  transform(selectedDate?: Date): boolean {
    const date = selectedDate ? new Date(selectedDate) : new Date();    
    return date < new Date();
  }
}

@Pipe({
  name: 'allowVehicleUpdate',
})
export class AllowVehicleUpdatePipe implements PipeTransform {
  transform(selectedDate: Date): boolean {
    return selectedDate > new Date();
  }
}

@Pipe({
  name: 'gender',
})
export class GenderPipe implements PipeTransform {
  transform(g?: string): string {
    switch (g) {
      case Genders.MALE:
        return 'man'

      case Genders.FEMALE:
        return 'woman'

      case Genders.Transgender:
        return 'transgender'
        
      default:
        return 'no_accounts'
    }
  }
}
@Pipe({
  name: 'genderColor',
})
export class GenderColorPipe implements PipeTransform {
  transform(g?: string): string {
    switch (g) {
      case Genders.MALE:
        return 'male'

      case Genders.FEMALE:
        return 'female'

      case Genders.Transgender:
        return 'transgender'
        
      default:
        return 'preferNtS'
    }
  }
}

@Pipe({
  name: 'sumOfField',
})
export class ArrayFieldSumPipe implements PipeTransform {
  transform(array: any[], fieldName?: string): boolean {
    // const initialValue = 0;
    const sumWithInitial = array.reduce(
      (accumulator, currentValue) => accumulator + ((fieldName != undefined)? currentValue[fieldName]:currentValue),
      0
    );
    return sumWithInitial;
  }
}

@Pipe({
  name: 'stopIdsToTripsText',
})
export class StopIdsToTripsTextPipe implements PipeTransform {
  transform(textIp: string): string {
    // const initialValue = 0;
    const trips = textIp.trim().split('\n\n');
    // const tripsStr = trips.map((x, idx)=> "Trip #"+ (idx+1) +" [ "+x.trim().split('\n').join(', ')+" ]" ).join('\n');

    let x=1;
    let tripsStr = '';
    for (let index = 0; index < trips.length; index++) {
      const e = trips[index];
      if(e.length>0){
        tripsStr = tripsStr + "Trip #"+ (x) +" [ "+e.trim().split('\n').join(', ')+" ]\n";
        x++;
      }
    }
    return tripsStr;//.substring(0,tripsStr.length);
  }
}

@Pipe({
  name: 'tripIdsToTripsText',
})
export class TripIdsToTripsTextPipe implements PipeTransform {
  transform(textIp: string): string {
    // const initialValue = 0;
    const trips = textIp.trim().split('\n\n');
    // const tripsStr = trips.map((x, idx)=> "Trip #"+ (idx+1) +" [ "+x.trim().split('\n').join(', ')+" ]" ).join('\n');

    let x=1;
    let tripsStr = '';
    for (let index = 0; index < trips.length; index++) {
      const e = trips[index];
      if(e.length>0){
        tripsStr = tripsStr + "Trips" +" [ "+e.trim().split('\n').join(', ')+" ]\n";
        x++;
      }
    }
    return tripsStr;//.substring(0,tripsStr.length);
  }
}

@Pipe({
  name: 'addressTrim'
})
export class AddressTrimPipe implements PipeTransform {
  transform(address: string): string {
    if (address.length > 40) {
      const trimmedAddress = address.substring(0, 50);
      const lastSpaceIndex = trimmedAddress.lastIndexOf(' ');
      // return trimmedAddress.substring(0, lastSpaceIndex) + '\n' + address.substring(lastSpaceIndex);
      return trimmedAddress.substring(0, lastSpaceIndex) + '...';
    }
    return address;
  }
}
@Pipe({
  name: 'addressBreak'
})
export class AddressBreakPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';

    const words = value.split(' ');
    let line = '';
    const lines = [];

    for (let i = 0; i < words.length; i++) {
      const word = words[i];

      if (line.length + word.length + 1 <= 45) {
        line += word + ' ';
      } else {
        lines.push(line.trim());
        line = word + ' ';
      }
    }

    if (line.trim().length > 0) {
      lines.push(line.trim());
    }

    return lines.join('\n');
  }
}
// @Pipe({ name: 'returnOrgNameById' })
// export class ReturnOrgNameById implements PipeTransform {
//   constructor(private apiEndpointsService: ApiEndpointsService){}

//   public transform(orgId: string): string {
//     // const html =  array.replace(/,|;/g, '<br>');

//     // const stop = timedStop.stopId as BusStop;
//     if (this.apiEndpointsService.all_organizations.length == 0) {
//         this.apiEndpointsService.getOrgs();
//     }
//     const org = this.apiEndpointsService.all_organizations.filter(x=> x._id == orgId);
//     if (org.length == 1) {
//       return org[0].brandName
//     }else{
//       return 'Org Not Found';
//     }
//   }
// }
@Pipe({
  name: 'minutesToHours'
})
export class MinutesToHoursPipe implements PipeTransform {
  transform(minutes: number): string {
    if (isNaN(minutes)) {
      return '-';
    }

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    let result = '';
    if (hours > 0) {
      result += hours + ' hrs ';
    }
    if (remainingMinutes > 0 || result === '') {
      result += remainingMinutes + ' min';
    }

    return result;
  }
}