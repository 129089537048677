import { DatePipe, formatDate } from '@angular/common';
import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

import { ApiEndpointsService } from 'src/app/core/services/api-endpoints.service';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { UIService } from 'src/app/core/services/ui.service';
import { Organization } from 'src/app/core/shared/interfaces';
import { UserType } from '../core/shared/types';

// declare var google: any; // Add this line to declare the 'google' variable

import { ChartType, Row, Column, } from 'angular-google-charts';
import Logging from '../core/services/Logging';
import { ApiRoutes } from '../core/shared/apiroutes';
import { UrlHelper } from '../core/shared/url-helper';

export interface ChartView {
  title: string;
  type: ChartType;
  data: Row[];
  columnNames: Column[];
  options: Object
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  constructor(
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private apiHttpService: ApiHttpService,
    private apiEndpointsService: ApiEndpointsService,
    private uiService: UIService // private ngZone: NgZone
  ) {}

  isLoading = false;

  companies: Organization[] = [];
  filteredCompanies = this.companies.slice();

  // myOrgType = '';
  selectedCompanyId = '';
  selectedStartDate: Date = new Date();
  selectedEndDate: Date = new Date();
  selectedCompany: number | undefined;
  // todaysDate: Date = new Date();

  isSysAdm = false;
  isVendorAdmin = false;
  isCompanyAdmin = false;
  isCompanyHelpDeskSecurity = false;

  // @ViewChild('chartContainer') chartContainer!: ElementRef;

  ngOnInit(): void {
    this.apiEndpointsService.getOrgs();
    this.isSysAdm = this.apiEndpointsService.getIsUserType(
      UserType.SystemAdmin
    );
    this.isVendorAdmin = this.apiEndpointsService.getIsUserType(
      UserType.VendorAdmin
    );
    this.isCompanyAdmin = this.apiEndpointsService.getIsUserType(
      UserType.CompanyAdmin
    );
    this.isCompanyHelpDeskSecurity = this.apiEndpointsService.getIsUserType(
      UserType.HelpDeskSecurity
    );

    if (this.isCompanyAdmin || this.isCompanyHelpDeskSecurity) {
      this.selectedCompany = this.apiEndpointsService.userProfile.org_id;
    }

    this.apiEndpointsService.all_organizations_changed.subscribe(
      (all_vehiclesChanged: boolean) => {
        if (all_vehiclesChanged) {
          this.companies = this.apiEndpointsService.all_organizations;
          this.filteredCompanies = this.companies.slice();
        }
      }
    );
  }

  selectFilter(filterValue: string, allOptions: any[], fieldName: string): any {
    if (filterValue.length > 2) {
      return allOptions.filter((option: { [x: string]: any }) =>
        option[fieldName].toLowerCase().includes(filterValue.toLowerCase())
      );
    } else {
      return allOptions.slice();
    }
  }

  charts:ChartView[] = [
    //   {
    //   title: 'DateWise No-Show Count',
    //   type: ChartType.ColumnChart,
    //   data: [
    //     ['02/08/2023', 3, ],
    //     ['07/08/2023', 1],
    //     ['08/08/2023', 2],
    //   ],
    //   columnNames:['Date', 'No-Show Count']
    //   // options:
    // },
  //   {
  //     "title": "Monthly No-Show Count (08 Aug to 09 Aug 2023)",
  //     type: ChartType.ColumnChart,
  //     "data": [
  //         [
  //             "August",
  //             74
  //         ]
  //     ],
  //     "columnNames": [
  //         "Month",
  //         "No-Show Count"
  //     ],
  //     "options": {
  //         "height": 250,
  //         chartArea: {width:'80%', left:60},
  //         "vAxis": {
  //             "title": "No Show Count (74)"
  //         },
  //         "hAxis": {
  //             "title": "Month (1)"
  //         },
  //         "legend": {
  //             "position": "top",
  //             "maxLines": 3
  //         },
  //         colors: [ '35A29F', '98EECC','FCAEAE']
  //     }
  // },
  //     {
  //     title: 'Monthly Cab Count',
  //     type: ChartType.ColumnChart,
  //     data: [
  //       ['June', 6],
  //       ['July', 6],
  //       ['August', 6],
  //     ],
  //     columnNames:['Month', 'Cab Count'],
  //     options:{
  //       height: 250,
  //       vAxis: { title: 'Cab Count (totalcabs)' },
  //       hAxis: { title: 'Months (month Count)' },
  //       legend: { position: 'top', maxLines: 3 },
  //     }
  //   },
    //   {
    //   title: 'Cab/Employee/Guard Count',
    //   type: ChartType.ComboChart,
    //   data: [
    //     // ['Month', 'Cab', 'Employee', 'Guard'],
    //     ['Jan', 10, 5, 5],
    //     ['Feb', 15, 8, 5],
    //     ['Mar', 8, 12, 5],
    //     // Add more data as needed
    //   ],
    //   columnNames: ['Month', 'Cab', 'Employee', 'Guard'],
    //   options:{
    //     height: 250,
    //     chartArea: {width:'80%', left:60},
    //     vAxis: { title: 'Count' },
    //     hAxis: { title: 'Month (monthCount)' },
    //     bar: { groupWidth: '50%' },
    //     seriesType: 'bars',
    //     legend: { position: 'top', maxLines: 3 },
    //   }
    // },

  //   {
  //     "title": "Shift Wise Users (09 Aug to 09 Aug 2023)",
  //     "type": ChartType.BarChart,
  //     "data": [
  //         [
  //             "06:00:00",
  //             33
  //         ],
  //         [
  //             "06:30:00",
  //             9
  //         ],
  //         [
  //             "07:00:00",
  //             3
  //         ],
  //         [
  //             "14:00:00",
  //             3
  //         ],
  //         [
  //             "14:30:00",
  //             5
  //         ],
  //         [
  //             "15:00:00",
  //             33
  //         ],
  //         [
  //             "15:30:00",
  //             9
  //         ],
  //         [
  //             "22:00:00",
  //             4
  //         ],
  //         [
  //             "23:00:00",
  //             3
  //         ],
  //         [
  //             "23:30:00",
  //             5
  //         ]
  //     ],
  //     "columnNames": [
  //         "Shift",
  //         "User Count (100)",
  //     ],
  //       options: {
  //         // width: 500,
  //         height: 250,
  //       // title: 'Column Chart with Counts',
  //       hAxis: { title: 'User Count (100)' },
  //       vAxis: { title: 'Shift (20)' },
  //       bar: { groupWidth: '25%' },
  //       // annotations: {
  //       //   alwaysOutside:true,
  //       //   textStyle: {
  //       //     fontSize: 12,
  //       //   },
  //       // },
  //       legend: { position: 'top', maxLines: 3 },
  //       colors: [ '35A29F', '98EECC','FCAEAE']
  //     }
  // },
    //   {
    //   title: 'ShiftWise Employee Count',
    //   type: ChartType.BarChart,
    //   data: [
    //     ['June', 200, ],
    //     ['July', 300, ],
    //     ['August', 550, ],
    //   ],
    //   columnNames:['Month', 'Employee Count',],
    //   options: {
    //     title: 'Column Chart with Counts',
    //     hAxis: { title: 'Categories' },
    //     vAxis: { title: 'Count' },
    //     bar: { groupWidth: '70%' },
    //     annotations: {
    //       textStyle: {
    //         fontSize: 12,
    //       },
    //     },
    //   }
    // },
  ];

  getCharts(startDate: string, endDate: string) {
    // Logging.info('getCharts', 'getCharts');
    // Logging.info('StartDate', typeof this.selectedStartDate);
    // Logging.info('EndDate', typeof this.selectedEndDate);
    // Logging.info('StartDate', startDate);
    // Logging.info('EndDate', endDate);

    if (typeof this.selectedStartDate === 'object') {
    }
    if (typeof this.selectedEndDate === 'object') {
    }

    this.uiService.setLoading(true);
    this.apiHttpService
      .get(
        UrlHelper.createUrlWithPathVariables(ApiRoutes.DailyCharts, [
          this.selectedCompany,
          startDate,
          endDate,
        ])
      )
      .subscribe({
        next: (value) => {
          Logging.info('response', value);
          if (value.error) {
            this.uiService.showSnackBar(value.message, 'okay', 5000);
          } else {
            this.charts = value.data;
          }
        },
        error: (error) => {
          Logging.error('getUsers', error);
          this.uiService.showSnackBar(error.message, 'okay', 5000);
        },
        complete: () => {},
      })
      .add(() => this.uiService.setLoading(false));
  }

  ngAfterViewInit() {
    // if (environment.production){
    this.fetchDate()
  // }
  }

  fetchDate() {
    const startDate = new DatePipe('en-IN').transform(
      this.selectedStartDate,
      'yyyy-MM-dd'
    );
    const endDate = new DatePipe('en-IN').transform(
      this.selectedEndDate,
      'yyyy-MM-dd'
    );
    // const d = new DatePipe('en-IN').transform(this.selectedStartDate, 'yyyy-MM-dd');

    if (this.selectedCompany != null && startDate != null && endDate != null) {
      this.getCharts(startDate, endDate);
    }
  }
}
