import { Directive, OnInit, Input, ElementRef } from '@angular/core';
import { FeatureFlagService } from '../feature-flag.service';

@Directive({
  selector: '[myRemoveIfFeatureOn]',
})
export class MyRemoveIfFeatureOnDirective implements OnInit {
  @Input('myRemoveIfFeatureOn') featureName!: string;
  @Input('isSysAdm') isSysAdm: boolean = false;
  // Same as myRemoveIfFeatureOff except call featureOn()
  constructor(
    private el: ElementRef,
    private featureFlagService: FeatureFlagService
  ) {}

  ngOnInit() {
    this.featureFlagService.isFlagsUpdated.subscribe(
      (isFlagsChanged: boolean) => {
        // Logging.info(new Date().valueOf(),'From myRemoveIfFeatureOn: FlagsChanged in featureFlagService,', isFlagsChanged);
        // Get Features of this User
        if (isFlagsChanged) {
          if (this.featureFlagService.featureOn(this.featureName)) {
            // Logging.info(new Date().valueOf(),'Removing Feature : ', this.featureName);
            if (!this.isSysAdm) {
              if (!!this.el.nativeElement.parentNode)
                this.el.nativeElement.parentNode.removeChild(
                  this.el.nativeElement
                );
            }
          }
        }
      }
    );
  }
}
